import React from "react";
import { LocationData } from "../datastructures";

interface MeasurementSourceLinkProperties {
  location: number;
  allLocationData: LocationData[];
  linkLocationLocal: boolean;
}

export const MeasurementSourceLink: React.FC<
  MeasurementSourceLinkProperties
> = ({ location, allLocationData, linkLocationLocal }) => {
  //console.log(allLocationData);
  //console.log(location);
  if (!allLocationData) {
    return <></>;
  }
  const filteredLocationData: LocationData[] = allLocationData.filter(
    (l) => l.id === location
  );
  if (filteredLocationData.length === 0) {
    return <></>;
  }
  const locationData: LocationData = filteredLocationData[0];
  const source: string = locationData.source;
  const locationName: string = locationData.location_name;
  const locationN: number = locationData.n;
  const locationE: number = locationData.e;

  const sourceUrls: Record<string, string> = {
    KNMI: "https://www.knmi.nl/nederland-nu/weer/waarnemingen",
    RWS: "https://www.rijkswaterstaat.nl/",
    NKV: "https://www.kitesurfvereniging.nl/",
    KZVS: "https://www.kzvs.nl/",
    WOLDERWIJD: "https://wolderwijdweer.nl/",
    WOW: "https://www.knmi.nl/kennis-en-datacentrum/uitleg/wow-nl",
    Meteotoren: "https://www.meteotoren.nl/",
    Airtime: "https://www.windmeting.nl/",
  };
  const sourceUrl: string = sourceUrls[source];
  //console.log(sourceUrls);
  //console.log(source);

  const sourceFriendlyNames: Record<string, string> = {
    WOLDERWIJD: "WolderwijdWeer",
    WOW: "KNMI WOW",
    RWS: "Rijkswaterstaat",
    NKV: "Nederlandse kitesurfvereniging",
    KZVS: "Kustzeilvereniging Scheveningen",
    Airtime: "Kitesurfvereniging Airtime",
  };
  const sourceFriendlyName: string =
    source in sourceFriendlyNames ? sourceFriendlyNames[source] : source;

  const localLocationUrl = "locationinfo?location=" + location;
  const mapsLocationUrl =
    "https://www.google.com/maps/?q=" + locationN + "," + locationE;

  return allLocationData ? (
    <>
      {linkLocationLocal ? (
        <a href={localLocationUrl}>{locationName}</a>
      ) : (
        <a href={mapsLocationUrl} target="blank" rel="noreferrer">
          {locationName}
        </a>
      )}{" "}
      weerstation van{" "}
      <a href={sourceUrl} target="blank" rel="noreferrer">
        {sourceFriendlyName}
      </a>
      {linkLocationLocal && (
        <>
          {" "}
          <a href={mapsLocationUrl} target="blank" rel="noreferrer">
            (maps)
          </a>
        </>
      )}
    </>
  ) : (
    <></>
  );
};
