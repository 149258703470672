export class WindowSize {
  constructor(
    public innerWidth: number,
    public innerHeight: number
  ) {}
}

export class KiteGear {
  bigKite = false;
  medKite = true;
  smallKite = false;
}

export class SoarGear {
  bigWing = true;
  smallWing = false;
}

export interface CalendarProperties {
  kiteGear: KiteGear;
  soarGear: SoarGear;
  spotData: SpotData[];
  homespotIds: number[];
}

export interface LocationData {
  id: number;
  location_name: string;
  lat: number; // rare waarde: 614562
  lon: number; // rare waarde: 192882
  n: number; // latitude
  e: number; // longitude
  col_generation_enabled: boolean;
  harm: boolean;
  gfs: boolean;
  gefs: boolean;
  sc_app: boolean;
  w2k_app: boolean;
  sc_site: boolean;
  w2k_site: boolean;
  source: string;
}

export interface NkvSpot {
  nkv_shortname: string;
  nkv_name: string;
  soarcast_spot_id: number;
}

export interface SpotData {
  id: number;
  wind_location: number;
  name: string;
  onshore: number;
  kite: boolean;
  soar: boolean;
  kitemindir: number;
  kitemaxdir: number;
  remarks: string;
  presure_location: number;
  temp_location: number;
  n: number; // latitude
  e: number; // longitude
  lier: boolean;
  lierdir: number;
  kf_n: number;
  kf_e: number;
  kf_row: number;
  kf_col: number;
}

export interface ActivityData {
  id: number;
  name: string;
  soaren: boolean;
  kiten: boolean;
  bigkite: boolean;
  medkite: boolean;
  smallkite: boolean;
  bigwing: boolean;
  smallwing: boolean;
  spot_id: number | null;
  calendar_threshold: number;
  remarks: string | null;
}

export interface GraphsData {
  activity_id: number;
  location_id: number;
  location_name: string;
  unifiedunits: string; // "m/s", "deg", "C"
}

export interface ExtraGraphsData {
  spot_id: number;
  act_id: number;
  location_id: number;
  unit: string; // "C", "hPa"
}

export interface ActivityLimits {
  activity_id: number;
  activity_name: string;
  location_id: number;
  location_name: string;
  location_source: string;
  event_type_name: string; // Windrichting,Windsnelheid,Luchttemperatuur
  min_value: number;
  max_value: number;
  units: string; // graden, m/s, grad.C
}

export interface CalendarActivity {
  validtime: number;
  activity_id: number;
  activity_name: string; // Soaren Zoutelande bergscherm,
  soaren: boolean;
  kiten: boolean;
  bigkite: boolean;
  medkite: boolean;
  smallkite: boolean;
  bigwing: boolean;
  smallwing: boolean;
  spot_id: number;
  calendar_threshold: number;
  chance: number;
  is_likely: boolean;
}

export interface Marker {
  etime: number;
  spot_id: number;
  spot_name: string;
  n: number; // latitude
  e: number; // longitude
  activity_name: string | null;
  soaren: boolean | null;
  kiten: boolean | null;
  bigkite: boolean | null;
  medkite: boolean | null;
  smallkite: boolean | null;
  bigwing: boolean | null;
  smallwing: boolean | null;
  activity_id: number | null;
  activity_green: boolean | null;
  why_not: string | null;
  oldest_measurement_age: number | null;
  sid: number | null;
  iconnr: number;
  nrofmarkersatspot: number;
}

export interface TideWindData {
  spot_id: number;
  validtime: number;
  velv: number;
  velu: number;
  sep: number;
  windrichting: number;
  wind_location: number;
  wind_u: number;
  wind_v: number;
  cur_speed: number;
  cur_dir: number;
  current_winddelta: number;
  upwind_cur_speed: number;
}
