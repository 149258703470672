import { BorderTable } from "./BorderTable";
import {friendlyWindUnitName, locationLabelFromNameAndSource, unitConversionFactor} from "../util/util";
import { ActivityLimits } from "../datastructures";
import React from "react";

interface ActivityLimitsTableProperties {
  activityId: number;
  windUnits: string;
  actLimitData: ActivityLimits[];
}

export const ActivityLimitsTable: React.FC<ActivityLimitsTableProperties> = ({
  activityId,
  windUnits,
  actLimitData,
}) => {
  const addWindConversionData = function (limit: ActivityLimits) {
    if (limit.units === "m/s") {
      limit.units = friendlyWindUnitName(windUnits);
      limit.min_value = Math.round(
        limit.min_value * unitConversionFactor(windUnits),
      );
      limit.max_value = Math.round(
        limit.max_value * unitConversionFactor(windUnits),
      );
    }
    return limit;
  };

  const filteredActLimitData = actLimitData
    .filter((l) => l.activity_id === activityId)
    .map(addWindConversionData);

  return (
    <>
      {filteredActLimitData.length ? (
        <>
          <div style={{ position: "relative" }}>
            De ingestelde limieten voor {filteredActLimitData[0].activity_name}{" "}
            zijn:
            <br />
          </div>
          <BorderTable>
            <tbody>
              <tr key="header">
                <th>locatie</th>
                <th>parameter</th>
                <th>min waarde</th>
                <th>max waarde</th>
                <th>eenheid</th>
              </tr>
              {filteredActLimitData.map((limit) => (
                <tr key={limit.event_type_name + limit.min_value}>
                  <td>{locationLabelFromNameAndSource(limit.location_name, limit.location_source)}</td>
                  <td>{limit.event_type_name}</td>
                  <td>{limit.min_value}</td>
                  <td>{limit.max_value}</td>
                  <td>{limit.units}</td>
                </tr>
              ))}
            </tbody>
          </BorderTable>
        </>
      ) : (
        <></>
      )}
    </>
  );
};
